export const PATH_NAME = {
  ROOT: '/',
  DASHBOARD: '/',
  LOGIN: '/login',
  SELECT_2FA_METHOD: '/login/select-2fa-method',
  AUTH_OTP_QR: '/login/auth-otp-qr',
  AUTH_OTP: '/login/auth-otp',
  SUCCESSFUL_LOGIN: '/login/successful-login',
  PARTNER_SIGN_UP: '/partner-sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  ERROR_404: '/404',
  INNER_ERROR_404: '/page/404',
  COMPANY: '/companies',
  COMPANY_NEW: '/companies/new',
  COMPANY_DETAIL: (id?: number | string) => `/companies/${id ?? ':companyId'}`,
  COMPANY_EDIT: (id?: number | string) => `/companies/${id ?? ':companyId'}/edit`,
  JOB_TYPE: '/job-types',
  JOB_TYPE_EDIT: (id: number | string | null) => `/job-types/${id ?? ':id'}/edit`,
  JOB_TYPE_NEW: '/job-types/new',
  HQ_USER: '/managers/hq-users',
  HQ_USER_EDIT: (id: number | string | null) => `/managers/hq-users/${id ?? ':id'}/edit`,
  HQ_USER_NEW: '/managers/hq-users/new',
  APPLICANT: '/applicants',
  APPLICANT_NEW: '/applicants/new',
  APPLICANT_DETAIL: (id?: string | number) => `/applicants/${id ?? ':applicantId'}`,
  APPLICANT_EDIT: (id?: string | number) => `/applicants/${id ?? ':applicantId'}/edit`,
  EDUCATION_INSTITUTE: '/education-institutes',
  EDUCATION_INSTITUTE_EDIT: (id: number | string | null) =>
    `/education-institutes/${id ?? ':id'}/edit`,
  EDUCATION_INSTITUTE_NEW: '/education-institutes/new',
  BILLING_RECORD: '/billing-record',
  JOD_CREDIT: '/jod-credits',
  JOD_DEBIT_CREDIT: '/jod-debit-credit',
  JOD_CREDIT_ASSIGN: '/jod-credits/assign',
  JOB_HISTORY: '/jod-credits/job-history',
  PAYMENT: '/payment',
  PAYMENT_SUMMARY: '/payment/job-summary',
  PAYMENT_SUMMARY_DETAIL: (id?: number | string) => `/payment/job-summary/${id ?? ':paymentId'}`,
  PAYMENT_PROCESSED: '/payment/job-payment',
  PAYMENT_ADJUSTMENTS: '/payment/payment-adjustments',
  PAYMENT_PROCESSED_DETAIL: (id?: number | string) => `/payment/job-payment/${id ?? ':paymentId'}`,
  PAYMENT_FUTURE: '/payment/future-payment',
  PAYMENT_FUTURE_DETAIL: (id?: number | string) => `/payment/future-payment/${id ?? ':paymentId'}`,
  REPORT: '/report',
  JOB_TEMPLATE: '/job-template',
  JOB_TEMPLATE_NEW: '/job-template/new',
  JOB_TEMPLATE_EDIT: (id?: string | number) => `/job-template/${id ?? ':jobTemplateId'}/edit`,
  MANAGER: '/managers',
  SUPERHQ_USER: '/superhq-users',
  MANAGER_NEW: '/managers/new',
  SUPERHQ_USER_NEW: '/superhq-users/new',
  MANAGER_EDIT: (id?: string | number) => `/managers/${id ?? ':managerId'}/edit`,
  SUPERHQ_USER_EDIT: (id?: string | number) =>
    `/superhq-users/edit/${id ?? ':userId'}`,
  JOB: '/jobs',
  ALL_JOBS: '/all-jobs',
  JOB_NEW: '/jobs/create',
  JOB_DETAIL: (id?: string | number) => `/jobs/${id ?? ':jobId'}`,
  JOB_EDIT: (id?: string | number) => `/jobs/${id ?? ':jobId'}/edit`,
  JOB_COPY: (id?: string | number) => `/jobs/${id ?? ':jobId'}/copy`,
  JOB_MANAGER: '/job-manager',
  JOB_CANCEL: '/job-manager/cancel-job',
  JOB_BULK_UPDATE: '/job-manager/update-job',
  CANDIDATE_JOB: (id?: string | number) => `/jobs/${id ?? ':jobId'}/candidates`,
  CANDIDATE_PROFILE_IN_JOB: (id?: string | number, applicantId?: string | number) =>
    `/jobs/${id ?? ':jobId'}/candidates/${applicantId ?? ':applicantId'}/profile`,
  CANDIDATE: '/jobs/candidates',
  CANDIDATE_PROFILE: (id?: string | number) => `/jobs/candidates/${id ?? ':applicantId'}/profile`,
  LOCATION: '/locations',
  LOCATION_NEW: '/locations/new',
  LOCATION_CREDIT_DETAIL: (id?: string | number) =>
    `/locations/${id ?? ':locationId'}/credit-history`,
  LOCATION_EDIT: (id?: string | number) => `/locations/${id ?? ':locationId'}/edit`,
  LOCATION_FEEDBACK: (id?: string | number) => `/locations/${id ?? ':locationId'}/feedback`,
  LOCATION_DETAIL: (id?: string | number) => `/locations/${id ?? ':locationId'}`,
  ACCOUNT_SETTINGS: '/account-settings',
  NOTIFICATION: '/notifications',
  LOCATION_PROFILE: '/location-profile',
  LOCATION_PROFILE_FEEDBACK: '/location-profile/location-feedback',

  ACCESS_CONFIGURATION: '/access-configuration',
  INTERNAL_USER: '/access-configuration/users',
  INTERNAL_USER_NEW: '/access-configuration/users/create',
  INTERNAL_USER_EDIT: (id?: string | number) =>
    `/access-configuration/users/edit/${id ?? ':userId'}`,
  INTERNAL_ROLE: '/access-configuration/roles',
  INTERNAL_ROLE_NEW: '/access-configuration/roles/create',
  INTERNAL_ROLE_EDT: (id?: string | number) =>
    `/access-configuration/roles/edit/${id ?? ':roleId'}`,
  PARTNER_ROLE_EDT: (id?: string | number) =>
    `/access-configuration/roles-partner/edit/${id ?? ':roleId'}`,
  PERMISSION: '/access-configuration/permission',
  CONFIGURATION: '/configuration',
  CONFIGURATION_NOTIFICATIONS: '/configuration/notifications',
  CONFIGURATION_OTHER: '/configuration/other',
  CONFIGURATION_JOD_BADGE: '/configuration/jod-badge',
  CONFIGURATION_APP: '/configuration/app-config',

  HOW_TO_USE: '/how-to-use',
  HOW_TO_POST_JOBS: '/how-to-use/how-to-post',
  HOW_TO_MAKE_SELECTIONS: '/how-to-use/how-to-select',
  HOW_TO_CLOCK_IN_AND_OUT: '/how-to-use/how-to-clock',
  VERIFY_EMAIL: '/portal/verify-email',
  PAYMENT_ADJUSTMENT_APPROVAL: (id?: string | number) => `/payment-adjustment-approval/${id ?? ':paymentApprovalId'}`,

  CERTIFICATE: '/certificates',
  CERTIFICATE_NEW: '/certificates/new',
  CERTIFICATE_EDIT: (id?: number | string) => `/certificates/${id ?? ':certificateId'}/edit`,

  HQ_ROLE_PERMISSION: '/role-permission',
  HQ_ROLE: '/role-permission/roles',
  HQ_ROLE_EDT: (id?: string | number) =>
    `/role-permission/roles-hq/edit/${id ?? ':roleId'}`,
  HQ_PERMISSION: '/role-permission/permissions',
};
